/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const PICTURE_USER = 'PICTURE_USER';
export const USER_OFFICES = 'USER_OFFICES';
export const SET_ROL_USER = 'SET_ROL_USER';
export const SET_OLD_PASS = 'SET_OLD_PASS';
export const USER_CONCESSIONAIRE = 'USER_CONCESSIONAIRE';
export const NAV_CONTENT = 'NAV_CONTENT';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
    'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/**GLOBALS */
export const GLOBAL_SET_DATA = 'GLOBAL_SET_DATA';

/** Wizard */
export const WIZARD_SET_DATA = 'WIZARD_SET_DATA';
export const TRIGGER_SET_DATA = 'TRIGGER_SET_DATA';
export const JUMP_FIRST_STEP = 'JUMP_FIRST_STEP';
export const RESPONSABLE_LOADED = 'RESPONSABLE_LOADED';
export const ERROR_INFORMATION_CREDIT = 'ERROR_INFORMATION_CREDIT';

/*** DEPENDENCE */
export const DEPENDENCE_SET_DATA = 'DEPENDENCE_SET_DATA';

/*** VALIDATIONS */
export const VALIDATIONS_SET_DATA = 'VALIDATIONS_SET_DATA';

/*** GENERAL */
export const MOSTRAR_CAMPOS_PERFIL = 'MOSTRAR_CAMPOS_PERFIL';
export const EJECUTAR_API_SALDO_USUARIO = 'EJECUTAR_API_SALDO_USUARIO';
export const NOTIFICACIONES_CORRESPONSAL = 'NOTIFICACIONES_CORRESPONSAL';
export const DATA_IMPRIMIR = 'DATA_IMPRIMIR';
export const GENERAR_IMPRESION = 'GENERAR_IMPRESION';
export const RECARGAR_CIERRE_CONTABLE = 'RECARGAR_CIERRE_CONTABLE';
export const LOADING_GENERAL = 'LOADING_GENERAL';

export * from './menu/actions';
export * from './settings/actions';
export * from './auth/actions';
export * from './globals/actions';
export * from './errors/actions';
export * from './dependencies/actions';
export * from './general/actions';
