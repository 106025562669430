import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import {
    LOGIN_USER,
    LOGOUT_USER,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
} from '../actions';

import {
    loginUserSuccess,
    loginUserError,
    forgotPasswordSuccess,
    forgotPasswordError,
    resetPasswordSuccess,
    resetPasswordError,
    setDataUserConcessionaire,
} from './actions';

import axios from 'axios';
import {
    servicePath,
} from '../../constants/defaultValues';

import rules from '../../config/rules';
import menuItems from '../../constants/menu';

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) =>
    await axios
        .post(`${servicePath}/users/login/`, {
            email: email,
            password: password,
        })
        .then((res) => res)
        .catch((error) => {
            return error;
        });

function* loginWithEmailPassword({ payload }) {
    const { email, password } = payload.user;
    const { history } = payload;

    try {

        const loginUser = yield call(
            loginWithEmailPasswordAsync,
            email,
            password
        );

        if (loginUser.data) {
            localStorage.setItem(
                'user_name',
                loginUser.data.user.first_name +
                    ' ' +
                    loginUser.data.user.last_name
            );
            localStorage.setItem('user_id', loginUser.data.user.pk);
            localStorage.setItem('token', loginUser.data.token.access);
            localStorage.setItem(
                'oldPass',
                loginUser.data.token.password_expired
                    ? loginUser.data.token.password_expired
                    : false
                // true
            );
            localStorage.setItem('role', loginUser.data.user.role[0]?.data?.code);
            localStorage.setItem(
                'rolesList',
                JSON.stringify(loginUser.data.user.role)
            );
            localStorage.setItem('corresponsal', loginUser.data.user.tipo_corresponsal_bancario?.value);


            if (loginUser.data.user?.concesionario) {
                const data_producto =
                    loginUser.data.user?.concesionario?.producto;
                const data = [];

                Object.keys(data_producto).forEach((item) => {
                    if (data_producto[item].value !== undefined) {
                        data.push(data_producto[item].value);
                    }
                });

                localStorage.setItem('producto', data.join(','));
                localStorage.setItem(
                    'concesionario',
                    loginUser.data.user?.concesionario?.pk
                );
            }

            yield put(
                setDataUserConcessionaire(
                    loginUser.data.user?.concesionario?.pk
                )
            );

            yield put(
                loginUserSuccess(
                    loginUser.data.user,
                    loginUser.data.token.access,
                    rules[`role_${loginUser.data.user.role[0].data.code}`],
                    menuItems[`role_${localStorage.getItem('role')}`]
                )
            );

            history.push('/');
        } else {
            var error = Object.keys(loginUser.response.data)[0];

            yield put(loginUserError(loginUser.response.data[error][0]));
        }
    } catch (error) {
        yield put(loginUserError(error));
    }
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
    // await auth.signOut().then(authUser => authUser).catch(error => error);
    // history.push('/')
};

function* logout({ payload }) {
    const { history } = payload;
    try {
        yield call(logoutAsync, history);
        localStorage.removeItem('user_name');
        localStorage.removeItem('user_id');
        localStorage.removeItem('role');
        localStorage.removeItem('rolesList');
        localStorage.removeItem('token');
        localStorage.removeItem('picture');
        localStorage.removeItem('producto');
        localStorage.removeItem('oldPass');
        localStorage.removeItem('initialArrTopNavMenu');
        localStorage.removeItem('arrTopNavMenu');
        localStorage.removeItem('concesionario');
    } catch (error) {}
}

export function* watchForgotPassword() {
    yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
    return await axios
        .post(`${servicePath}/reset-password/`, { email: email })
        .then((res) => res)
        .catch((error) => {
            return 'Correo Invalido';
        });
};

function* forgotPassword({ payload }) {
    const { email } = payload.forgotUserMail;
    try {
        const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
        if (forgotPasswordStatus.data) {
            yield put(forgotPasswordSuccess('success'));
        } else {
            yield put(
                forgotPasswordError(
                    forgotPasswordStatus.message
                        ? forgotPasswordStatus.message
                        : forgotPasswordStatus
                )
            );
        }
    } catch (error) {
        yield put(forgotPasswordError(error));
    }
}

export function* watchResetPassword() {
    yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
    return await axios
        .post(`${servicePath}/reset-password/verify-token/`, {
            token: resetPasswordCode,
            password: newPassword,
        })
        .then((res) => res)
        .catch((error) => error);
};

function* resetPassword({ payload }) {
    const { newPassword, resetPasswordCode } = payload;
    try {
        const resetPasswordStatus = yield call(
            resetPasswordAsync,
            resetPasswordCode,
            newPassword
        );

        if (resetPasswordStatus.data) {
            yield put(resetPasswordSuccess('success'));
        } else {
            var error = Object.keys(resetPasswordStatus.response.data)[0];
            yield put(
                //resetPasswordError(resetPasswordStatus.response.data[error][0])
                resetPasswordError(resetPasswordStatus.response.data[error])
            );
        }
    } catch (error) {
        yield put(resetPasswordError(error));
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchForgotPassword),
        fork(watchResetPassword),
    ]);
}
