import {
    iconRequest,
    iconClient,
    iconReports,
    iconParameters,
    iconCalculator,
    iconReportsDefault,
    iconParametersDefault,
    iconSearch,
    iconPaper,
} from '../defaultValues';

const data = {
    role_1a77c2f93e7441d1a0552a0832a21739: [
        {
            id: 'digital',
            icon: 'iconsminds-three-arrow-fork',
            label: 'Contenido digital',
            to: `/app/acquireAccount`,
        },
        {
            id: 'corresponsal',
            icon: 'iconsminds-three-arrow-fork',
            label: 'Corresponsal bancario',
            to: `/second-menu`,
            // roles: [UserRole.Admin, UserRole.Editor],
            subs: [
                {
                    id: 'correspondentOperation',
                    icon: 'simple-icon-paper-plane',
                    label: 'Corresponsal',
                    to: `/app/correspondentOperation`,
                }
            ],
        },
        {
            id: 'factura',
            icon: iconPaper,
            label: 'Factura',
            to: `/second-menu`,
            subs: [
                {
                    id: 'balanceSheet',
                    icon: 'simple-icon-paper-plane',
                    label: 'Prefactura',
                    to: `/app/preInvoice`,
                },
                {
                    id: 'historico',
                    icon: 'simple-icon-paper-plane',
                    label: 'Historico factura',
                    to: `/app/preInvoice/listHistory`,
                }
            ],
        },
    ],
};
export default data;
