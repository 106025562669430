import {
    MOSTRAR_CAMPOS_PERFIL,
    EJECUTAR_API_SALDO_USUARIO,
    NOTIFICACIONES_CORRESPONSAL,
    DATA_IMPRIMIR,
    GENERAR_IMPRESION,
    RECARGAR_CIERRE_CONTABLE,
    LOADING_GENERAL,

} from '../actions';

const INIT_STATE = {
    mostrarCamposPerfil: null,
    ejecutarApiSaldoUsuario: null,
    notificacionesCorresponsal: {},
    dataImprimir: {},
    generarImpresion: null,
    recargarCierreContable: null,
    loadingGeneral: null,

};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case MOSTRAR_CAMPOS_PERFIL:
            return Object.assign({}, state, action.payload);
        case EJECUTAR_API_SALDO_USUARIO:
            return Object.assign({}, state, action.payload);
        case NOTIFICACIONES_CORRESPONSAL:
            return Object.assign({}, state, action.payload);
        case DATA_IMPRIMIR:
            return Object.assign({}, state, action.payload);
        case GENERAR_IMPRESION:
            return Object.assign({}, state, action.payload);
        case RECARGAR_CIERRE_CONTABLE:
            return Object.assign({}, state, action.payload);
        case LOADING_GENERAL:
            return Object.assign({}, state, action.payload);
        default:
            return { ...state };
    }
};
