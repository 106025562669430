import {
    iconRequest,
    iconClient,
    iconReports,
    iconParameters,
    iconCalculator,
    iconReportsDefault,
    iconParametersDefault,
    iconSearch,
    iconPaper,
} from '../defaultValues';

const data = {
    role_baef56b8304b452987a01352d78a15c3: [
        {
            id: 'corresponsal',
            icon: 'iconsminds-three-arrow-fork',
            label: 'Corresponsal bancario',
            to: `/second-menu`,
            // roles: [UserRole.Admin, UserRole.Editor],
            subs: [
                {
                    id: 'correspondentOperation',
                    icon: 'simple-icon-paper-plane',
                    label: 'Corresponsal',
                    to: `/app/correspondentOperation`,
                },
                {
                    id: 'typeBankingCorrespondent',
                    icon: 'simple-icon-paper-plane',
                    label: 'Tipo Corresponsal',
                    to: `/app/typeBankingCorrespondent`,
                },
                {
                    id: 'bankingCorrespondent',
                    icon: 'simple-icon-paper-plane',
                    label: 'Acción Corresponsal',
                    to: `/app/bankingCorrespondent`,
                },
            ],
        },
    ],
};
export default data;
