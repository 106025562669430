import roleVendedor from './roleVendedor';
import roleAdministrator from './roleAdministrator';
import roleContenidoDigital from './roleContenidoDigital';
import roleCorresponsal from './roleCorresponsal';
import roleFacturacion from './roleFacturacion';
import roleOrdenServicio from './roleOrdenServicio';
import rolePuntoVenta from './rolePuntoVenta';

export default Object.assign(
    {}, 
    roleVendedor, 
    roleAdministrator,
    roleContenidoDigital,
    roleCorresponsal,
    roleFacturacion,
    roleOrdenServicio,
    rolePuntoVenta);
