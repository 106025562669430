/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'es';
export const localeOptions = [
    { id: 'en', name: 'English - LTR', direction: 'ltr' },
    { id: 'es', name: 'Español', direction: 'ltr' },
    { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const firebaseConfig = {
    apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
    authDomain: 'gogo-react-login.firebaseapp.com',
    databaseURL: 'https://gogo-react-login.firebaseio.com',
    projectId: 'gogo-react-login',
    storageBucket: 'gogo-react-login.appspot.com',
    messagingSenderId: '216495999563',
};

export const navTagInitialStorage = 'initialArrTopNavMenu';
export const navTagArrStorage = 'arrTopNavMenu';

export const searchPath = '/app/pages/search';
export const servicePath = 'https://api-servide.william-patino.com';
// export const servicePath = 'https://api.servireddigital.com	';

// export const servicePath = 'http://localhost:8001';

export const urlPhotoProfileDefault =
    'https://www.uic.mx/posgrados/files/2018/05/default-user-300x300.png';

export const mode = 'debug'; // production | debug

/*
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.blueyale';
export const isAuthGuardActive = true;
export const colors = [
    'bluenavy',
    'blueyale',
    'blueolympic',
    'greenmoss',
    'greenlime',
    'purplemonster',
    'orangecarrot',
    'redruby',
    'yellowgranola',
    'greysteel',
];
export const defaultDirection = 'ltr';
export const isDarkSwitchActive = true;
export const themeRadiusStorageKey = '__theme_radius';
export const isDemo = false;
export const iconRequest = 'iconsminds-support';
export const iconClient = 'iconsminds-business-mens';
export const iconReports = 'iconsminds-line-chart-1';
export const iconParameters = 'simple-icon-settings';
export const iconCredit = 'iconsminds-shop-4';
export const iconCalculator = 'simple-icon-calculator';
export const iconReportsDefault = 'iconsminds-pie-chart-3';
export const iconParametersDefault = 'iconsminds-equalizer';
export const iconAffiliate = 'simple-icon-user-follow';
export const iconConcessionaire = 'iconsminds-office';
export const iconCertificate = 'iconsminds-diploma-2';
export const iconPerformanceExcutive = 'iconsminds-line-chart-1';
export const iconSearch = 'iconsminds-magnifi-glass';
export const iconPreAprobador = 'simple-icon-check';
export const iconDigitalSignature = 'iconsminds-pen';
export const iconPerson = 'simple-icon-people';
export const iconNeighborhood = 'simple-icon-location-pin';
export const iconConcessionaireSecondary = 'iconsminds-shop-4';
export const iconPays = 'iconsminds-files';
export const iconZonification = 'iconsminds-map2';
export const simpleIconEye = 'simple-icon-eye';
export const iconUsers = 'simple-icon-user-following';
export const iconDocs = 'iconsminds-library';
export const DocsDesembolsados = 'iconsminds-folder-add--';
export const iconParametersGyg = 'iconsminds-gear';
export const iconPaper = 'iconsminds-paper'
