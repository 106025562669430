const options = {
    estado_usuario: [
        {
            value: 1,
            label: 'ACTIVO',
        },
        {
            value: 0,
            label: 'INACTIVO',
        },
    ],
    tipo_usuario: [
        {
            value: '0',
            label: 'PREPAGO CONTENIDO DIGITAL',
        },
        {
            value: '1',
            label: 'POSTPAGO CONTENIDO DIGITAL',
        },
        {
            value: '2',
            label: 'PREPAGO CORRESPONSAL BANCARIO',
        },
        {
            value: '3',
            label: 'POSTPAGO CORRESPONSAL BANCARIO',
        },
    ],
    estado: [
        {
            value: 'ACTIVO',
            label: 'ACTIVO',
        },
        {
            value: 'INACTIVO',
            label: 'INACTIVO',
        },
    ],
    si_no: [
        {
            value: 'SI',
            label: 'SI',
        },
        {
            value: 'NO',
            label: 'NO',
        },
    ],
    tipo_corresponsal: [
        {
            value: 'RECARGA',
            label: 'RECARGA',
        },
        {
            value: 'RETIRO',
            label: 'RETIRO',
        },
    ],
    estado_operacion_corresponsal: [
        {
            value: 'PENDIENTE',
            label: 'PENDIENTE',
        },
        {
            value: 'APROBADA',
            label: 'APROBADA',
        },
        {
            value: 'RECHAZADA',
            label: 'RECHAZADA',
        },
    ],
    clasificacion_tramite: [
        {
            value: 'TRAMITE_EN_LINEA',
            label: 'TRAMITE EN LINEA',
        },
        {
            value: 'TRAMITE_ESPECIAL',
            label: 'TRAMITE ESPECIAL',
        },
    ],
    estado_tramite: [
        {
            value: 'PENDIENTE',
            label: 'PENDIENTE',
        },
        {
            value: 'ATENDIDO',
            label: 'ATENDIDO',
        },
        {
            value: 'CANCELADO',
            label: 'CANCELADO',
        },
    ],
    anio: [
        {
            value: '2022',
            label: '2022',
        },
        {
            value: '2023',
            label: '2023',
        },
        {
            value: '2024',
            label: '2024',
        },
        {
            value: '2025',
            label: '2025',
        },
        {
            value: '2026',
            label: '2026',
        },
        {
            value: '2027',
            label: '2027',
        },
        {
            value: '2028',
            label: '2028',
        },
        {
            value: '2029',
            label: '2029',
        },
        {
            value: '2030',
            label: '2030',
        },
    ],
    mes: [
        {
            value: '01',
            label: 'ENERO',
        },
        {
            value: '02',
            label: 'FEBRERO',
        },
        {
            value: '03',
            label: 'MARZO',
        },
        {
            value: '04',
            label: 'ABRIL',
        },
        {
            value: '05',
            label: 'MAYO',
        },
        {
            value: '06',
            label: 'JUNIO',
        },
        {
            value: '07',
            label: 'JULIO',
        },
        {
            value: '08',
            label: 'AGOSTO',
        },
        {
            value: '09',
            label: 'SEPTIEMBRE',
        },
        {
            value: '10',
            label: 'OCTUBRE',
        },
        {
            value: '11',
            label: 'NOVIEMBRE',
        },
        {
            value: '12',
            label: 'DICIEMBRE',
        },
    ],
    semana: [
        {
            value: '1',
            label: 'SEMANA 1',
        },
        {
            value: '2',
            label: 'SEMANA 2',
        },
        {
            value: '3',
            label: 'SEMANA 3',
        },
        {
            value: '4',
            label: 'SEMANA 4',
        }
    ],
    estado_cierre: [
        {
            value: 'ABIERTO',
            label: 'ABIERTO',
        },
        {
            value: 'CERRADO',
            label: 'CERRADO',
        },
        {
            value: 'INACTIVO',
            label: 'INACTIVO',
        },
    ],
    direccion: [
        {
            value: 'CALLE',
            label: 'CALLE',
        },
        {
            value: 'CARRERA',
            label: 'CARRERA',
        },
        {
            value: 'AVENIDA',
            label: 'AVENIDA',
        },
        {
            value: 'AVENIDA CARRERA',
            label: 'AVENIDA CARRERA',
        },
        {
            value: 'AVENIDA CALLE',
            label: 'AVENIDA CALLE',
        },
        {
            value: 'CIRCULAR',
            label: 'CIRCULAR',
        },
        {
            value: 'CIRCUNVALAR',
            label: 'CIRCUNVALAR',
        },
        {
            value: 'CORREGIMIENTO',
            label: 'CORREGIMIENTO',
        },
        {
            value: 'DIAGONAL',
            label: 'DIAGONAL',
        },
        {
            value: 'MANZANA',
            label: 'MANZANA',
        },
        {
            value: 'TRANSVERSAL',
            label: 'TRANSVERSAL',
        },
        {
            value: 'VIA',
            label: 'VÍA',
        },
        {
            value: 'VEREDA',
            label: 'VEREDA',
        },
    ],

};

export default options;
